import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
// import App from "./App";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./services/utils/configureStore";
import { history } from "./services/utils/history";
import Spinner from "./components/loadingIndicator/Spinner";
import styled from "./services/utils/styled";
import ViewPortProvider from "./providers/ViewPortProvider";
// import InterruptedConnectionNotification from "./services/notification/InterruptedConnectionNotification";
// import DownloadNotification from "./services/notification/DownloadNotification";

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
`;

const initialState = {};
const store = configureStore(initialState, history);

const App = lazy(() => import('./App'));
const InterruptedConnectionNotification = lazy(() => import('./services/notification/InterruptedConnectionNotification'));
const DownloadNotification = lazy(() => import('./services/notification/DownloadNotification'));

ReactDOM.render(
    <Provider store={store}>
        <ViewPortProvider>
            <React.StrictMode>
                <BrowserRouter>
                    <Suspense fallback={<Wrapper><Spinner /></Wrapper>}>
                        <App />
                        <InterruptedConnectionNotification />
                        <DownloadNotification />
                    </Suspense>
                </BrowserRouter>
            </React.StrictMode>
        </ViewPortProvider>
    </Provider>,
    document.getElementById("root") as HTMLElement
);
